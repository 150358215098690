<template>
  <span>
    <span v-if="$i18n.locale == 'th'">{{ datetime | sumdatetime }}</span>
    <span v-if="$i18n.locale == 'en'">{{ datetime | sumdatetimeen }}</span>
  </span>
</template>

<script>
import { sumdatetimeen, sumdatetime } from '@/plugins/filters'

export default {
  filters: {
    sumdatetime,
    sumdatetimeen,
  },
  props: {
    datetime: String,
  },
}
</script>
