<template>
  <v-row class="px-2 mb-2">
    <v-col
      cols="12"
      md="6"
      lg="3"
      class="py-0"
    >
      <v-text-field
        v-model="searchtext"
        outlined
        dense
        :label="`${$t('search')} ${$t('pressEnterForSearch')}`"
        hide-details
        @keypress.enter="sendDate"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="3"
      class="py-0"
    >
      <v-select
        v-model.trim="user_id"
        class="set-text"
        :items="userList"
        dense
        outlined
        :label="$t('staff')"
        item-text="user_fullname"
        item-value="user_id"
        hide-details
        @change="sendDate"
      ></v-select>
    </v-col>

    <v-col
      cols="12"
      md="6"
      lg="3"
      class="py-0"
    >
      <v-dialog
        ref="datePicker"
        v-model.trim="isShowDate"
        :return-value.sync="searchdate"
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model.trim="searchdate"
            :label="$t('date')"
            readonly
            hide-details
            outlined
            dense
            v-bind="attrs"
            v-on="on"
          >
            <template v-slot:append>
              <v-tooltip
                color="#212121"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="searchdate = ''"
                  >
                    {{ icons.mdiCalendarBlank }}
                  </v-icon>
                </template>
                <span>{{ $t("all_dates") }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model.trim="searchdate"
          :locale="$i18n.locale"
          scrollable
        >
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="$refs.datePicker.save(searchdate)"
          >
            {{ $t("confirm") }}
          </v-btn>
          <v-btn
            text
            color="secondary"
            @click="isShowDate = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-date-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import { mdiCalendarBlank } from '@mdi/js'
import userRole from '@/api/userRole'
import { i18n } from '@/plugins/i18n'

export default {
  setup(props, { emit }) {
    const moment = require('moment')
    const userList = ref([])
    const user_id = ref('')
    const searchtext = ref('')
    const searchdate = ref(moment(new Date()).format('YYYY-MM-DD'))
    const isShowDate = ref(false)

    userRole.userRoleList({
      role_id: [1, 2],
    }).then(res => {
      userList.value = res
      userList.value.unshift({
        user_id: '',
        user_fullname: i18n.t('all_staff'),
      })
    })
    onMounted(() => {
      sendDate()
    })
    const sendDate = () => {
      emit('onSendDate', {
        searchtext: searchtext.value,
        searchdate: searchdate.value,
        user_id: user_id.value,
      })
    }

    watch([searchdate], () => {
      sendDate()
    })

    return {
      userList,
      user_id,
      searchtext,
      searchdate,
      sendDate,
      isShowDate,
      icons: {
        mdiCalendarBlank,
      },
    }
  },

}
</script>
