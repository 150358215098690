import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'
import { api as api2 } from '../mainNode'

const logcustomer = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logcustomer',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get customer log list err : ', err)

      return {}
    })

  return response
}

const logsms = async body => {
  ////await refreshToken()

  const response = await api
    .post({
      path: 'logsendsms',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get sms send log list err : ', err)

      return {}
    })

  return response
}

const logpoint = async body => {
  ////await refreshToken()

  const response = await api
    .post({
      path: 'logpoint',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get point log list err : ', err)

      return {}
    })

  return response
}

const logorder = async body => {
  ////await refreshToken()

  const response = await api
    .post({
      path: 'logorder',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get order log list err : ', err)

      return {}
    })

  return response
}

const logorderpay = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logorderpay',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get logorderpay log list err : ', err)

      return {}
    })

  return response
}

const logbankpay = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logbankpay',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get logbankpay log list err : ', err)

      return {}
    })

  return response
}

const logappoint = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logappoint',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get logappoint log list err : ', err)

      return {}
    })

  return response
}

const logbooking = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logbooking',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get logbooking log list err : ', err)

      return {}
    })

  return response
}

const logcheckq = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logcheckq',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get logcheckq log list err : ', err)

      return {}
    })

  return response
}

const logopd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logopd',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get logopd log list err : ', err)

      return {}
    })

  return response
}

const logchecking = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logchecking',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get logchecking log list err : ', err)

      return {}
    })

  return response
}

const logchecklist = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logchecklist',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get logchecklist log list err : ', err)

      return {}
    })

  return response
}

const logcourse = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logcourse',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get logcourse log list err : ', err)

      return {}
    })

  return response
}

const logserving = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logserving',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get logserving log list err : ', err)

      return {}
    })

  return response
}

const logdrug = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logdrug',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get logdrug log list err : ', err)

      return {}
    })

  return response
}

const logdrugorder = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logdrugorder',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get logdrugorder log list err : ', err)

      return {}
    })

  return response
}

const logdrughistory = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logdrughistory',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get logdrughistory log list err : ', err)

      return {}
    })

  return response
}

const logservinguser = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logservinguser',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get logservinguser log list err : ', err)

      return {}
    })

  return response
}

const logpointonline = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logpointonline',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get logpointonline log list err : ', err)

      return {}
    })

  return response
}

const loguserlogin = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'loguserlogin',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get loguserlogin log list err : ', err)

      return {}
    })

  return response
}

const logmember = async body => {
  ////await refreshToken()
  const response = await api2
    .post({
      path: 'logmember',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get member log list err : ', err)

      return {}
    })

  return response
}

const logpo = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'logpurchaseorder',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get po log list err : ', err)

      return {}
    })

  return response
}


export default {
  logpo,
  logcustomer,
  logsms,
  logpoint,
  logorder,
  logorderpay,
  logbankpay,
  logappoint,
  logbooking,
  logcheckq,
  logopd,
  logchecking,
  logchecklist,
  logcourse,
  logserving,
  logdrug,
  logdrugorder,
  logdrughistory,
  logservinguser,
  logpointonline,
  loguserlogin,
  logmember,
}
